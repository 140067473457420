<template>
  <ServiceLayout>
    <div class="md-service-1">
      <!-- 广告图 -->
      <Banner :list="bannerList" height="4.92rem" v-ani.fade-up />
      <!-- 规范查询 -->
      <ServiceDownload title='规范查询' :list="queryArr" />
    </div>
  </ServiceLayout>
</template>

<script>
import { IndexBannerApi } from "@/request/api/user";
import { QueriesIndexApi } from "@/request/api/service";
export default {
  created() {
    // 轮播图
    IndexBannerApi({ from: 425 }).then(({ status, data }) => {
      if (status == 200) {
        this.bannerList = data;
      }
    })
    // 规范查询
    QueriesIndexApi().then(({ status, data }) => {
      if (status == 200) {
        this.queryArr = data;
      }
    })
  },
  data() {
    return {
      bannerList: [], // 轮播图列表
      queryArr: [],
    };
  },
};
</script>

<style lang="scss" scoped></style>